
export enum Mutations {
    SET_VALUE_LOADING = "SET_VALUE_LOADING"
    , SET_LOADINGS = "SET_LOADINGS"
    , SET_DATA_CASH = "SET_DATA_CASH"
    , SET_DATA_OPENED_CASH = "SET_DATA_OPENED_CASH"
    , SET_CASHREGISTER_COMBO = "SET_CASHREGISTER_COMBO"
    , SET_OPENING_PAGE = 'SET_OPENING_PAGE'
    , SET_SALE_CHARGES = 'SET_SALE_CHARGES'
    , SET_FILTERS = "SET_FILTERS"
    , SET_PAGINATION = "SET_PAGINATION"
    , SET_TOTAL_RECORDS = "SET_TOTAL_RECORDS"
    , SET_TABLE_DATA = "SET_TABLE_DATA"

    , SET_FILTERS_CHARGES = "SET_FILTERS_CHARGES"
    , SET_PAGINATION_CHARGES = "SET_PAGINATION_CHARGES"
    , SET_TOTAL_RECORDS_CHARGES = "SET_TOTAL_RECORDS_CHARGES"
    , SET_TABLE_DATA_CHARGES = "SET_TABLE_DATA_CHARGES"
    , SET_SALES_CHARGES_TOTAL = "SET_SALES_CHARGES_TOTAL"
    , SET_VIEW_ADDITIONAL = "SET_VIEW_ADDITIONAL"
    , SET_VIEW_RETIRE = "SET_VIEW_RETIRE"
    
}
