import { getMessageError, getMessageHeader } from '@/core/helpers/messageFromPulmeros';
import { translate } from '@/core/plugins/i18n';
import { AxiosResponse } from 'axios';
import ApiService from '../ApiService';
import GenericCrudService from "../general/GenericCrudService";
import SwalMessageService from '../SwalMessageService';
import UserInfoCash from '@/models/cash/UserInfoCash';
import OpenCash from '@/models/cash/OpenCash';
import CashRegisterIncrease from '@/models/cash/CashRegisterIncrease';
import CashRegisterRetire from '@/models/cash/CashRegisterRetire';
import OpenedCashRegisterMovent from '@/models/cash/OpenedCashRegisterMovent';
import CashRegistersCombo from '@/models/cash/CashRegistersCombo';
import CashClose from '@/models/cash/CashClose';
import PaginationRequest from '@/models/general/PaginationRequest';
import PaginationResponse from '@/models/general/PaginationResponse';
import FilterSalesCharges from '@/models/cash/FilterSalesCharges';
import RowSalesCharges from '@/models/cash/RowSalesCharges';
import RowOpeningList from '@/models/cash/RowOpeningList';
import FilterOpeningList from '@/models/cash/FilterOpeningList';
import SalesChargesTotal from '@/models/cash/SalesChargesTotal';

export default class CashService 
    extends GenericCrudService<UserInfoCash, UserInfoCash> 
    {

    constructor(){
        super("/CashRegisterMovements", new SwalMessageService() );
    }
    
    /**
     * Recupera información de la venta para el grid
     * @param request filtros para recuperar ventas
     * @returns listado de ventas
     */
    async getInfoCash(): Promise<AxiosResponse<UserInfoCash>> {
        return  ApiService.get<UserInfoCash>('/Cashiers', 'GetCashierByUser'
        ).then( resp => {
            return resp;
        }).catch(error => {
            return { data: null} as AxiosResponse;
        });
    }

    async getOpenedCashRegisterMovent(): Promise<AxiosResponse<OpenedCashRegisterMovent>> {
        return  ApiService.get<UserInfoCash>(this.apiController, 'OpenedCashRegisterMoventByUser'
        ).then( resp => {
            return resp;
        }).catch(error => {
            return { data: null} as AxiosResponse;
        });
    }

    async getGetSalesChargesTotal(id: string): Promise<AxiosResponse<SalesChargesTotal>> {
        return  ApiService.get<SalesChargesTotal>(this.apiController, `GetSalesChargesTotal?cashRegisterMovementId=${id}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            return { data: null} as AxiosResponse;
        });
    }

    async putApprovalClosed(id: string): Promise<AxiosResponse<boolean>> {
        return  ApiService.put(this.apiController + `/AproveClosureCashRegister?id=${id}`,{}
        ).then( resp => {
            if(resp.status >= 201 && resp.status < 500){
                getMessageHeader(resp.headers, translate('MESSAGES.SAVE_CORRECT'), this.messageService);
            }
            return resp;
        }).catch(error => {
            return { data: null} as AxiosResponse;
        });
    }

    async getCashRegistersCombo(): Promise<AxiosResponse<CashRegistersCombo>> {
        return  ApiService.get<UserInfoCash>('/CashRegisters', 'combo'
        ).then( resp => {
            return resp;
        }).catch(error => {
            return { data: null} as AxiosResponse;
        });
    }

    async openCashRegister(request: OpenCash): Promise<AxiosResponse<boolean>> {
        return  ApiService.post<boolean>(this.apiController + '/OpenCashRegister'
            ,request
        ).then( resp => {
            if(resp.status >= 201 && resp.status < 500){
                getMessageHeader(resp.headers, translate('MESSAGES.SAVE_CORRECT'), this.messageService);
            }
            return {...resp, data: (resp.status >= 201 && resp.status >= 204 && resp.status < 300)};
        }).catch(error => {
            getMessageError(error, this.messageService, "MessageError");
            return { data: null} as AxiosResponse;
        });
    }

    async cashRegisterIncrease(request: CashRegisterIncrease): Promise<AxiosResponse<boolean>> {
        return  ApiService.post<boolean>(this.apiController + '/CashRegisterIncrease'
            ,request
        ).then( resp => {
            if(resp.status >= 201 && resp.status < 500){
                getMessageHeader(resp.headers, translate('MESSAGES.SAVE_CORRECT'), this.messageService);
            }
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService, "MessageError");
            return { data: null} as AxiosResponse;
        });
    }

    async cashRegisterRetire(request: CashRegisterRetire): Promise<AxiosResponse<boolean>> {
        return  ApiService.post<boolean>(this.apiController + '/CashRegisterRetire'
            ,request
        ).then( resp => {
            if(resp.status >= 201 && resp.status < 500){
                getMessageHeader(resp.headers, translate('MESSAGES.SAVE_CORRECT'), this.messageService);
            }
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService, "MessageError");
            return { data: null} as AxiosResponse;
        });
    }

    async cashClose(request: CashClose): Promise<AxiosResponse<boolean>> {
        return  ApiService.put(this.apiController + '/ClosureCashRegister'
            ,request
        ).then( resp => {
            if(resp.status >= 201 && resp.status < 500){
                getMessageHeader(resp.headers, translate('MESSAGES.SAVE_CORRECT'), this.messageService);
            }
            return {...resp, data: (resp.status >= 200 && resp.status >= 204 && resp.status < 300)};
        }).catch(error => {
            getMessageError(error, this.messageService, "MessageError");
            return { data: null} as AxiosResponse;
        });
    }

    async getTableOpeningList(paginator: PaginationRequest, filter: FilterOpeningList): Promise<AxiosResponse<PaginationResponse<RowOpeningList>>> {
        return  ApiService.get<PaginationResponse<RowOpeningList>>(this.apiController, 
            `GetOpeningPagedList?`
            + `CurrentPage=${paginator.currentPage}`
            + `&PageSize=${paginator.pageSize}`
            + (filter.CashRegisterId != undefined ? `&Criteria.CashRegisterId=${filter.CashRegisterId}` : '')
            + (filter.OpeningBegin != undefined && filter.OpeningBegin != '' ? `&Criteria.OpeningBegin=${filter.OpeningBegin}` : '')
            + (filter.OpeningEnd != undefined && filter.OpeningEnd != '' ? `&Criteria.OpeningEnd=${filter.OpeningEnd}` : '')
            + (filter.ClosureBegin != undefined && filter.ClosureBegin != '' ? `&Criteria.ClosureBegin=${filter.ClosureBegin}` : '')
            + (filter.ClosureEnd != undefined && filter.ClosureEnd != '' ? `&Criteria.ClosureEnd=${filter.ClosureEnd }` : '')
            + (filter.IsClosure != undefined ? `&Criteria.IsClosure=${filter.IsClosure == 3}` : '')
           
        ).then( resp => {
            return resp;
        }).catch(error => {
            //getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async getTableSalesCharges(paginator: PaginationRequest, filter: FilterSalesCharges): Promise<AxiosResponse<PaginationResponse<RowSalesCharges>>> {
        return  ApiService.get<PaginationResponse<RowSalesCharges>>(this.apiController, 
            `GetSalesChargesPagedList?`
            + `CurrentPage=${paginator.currentPage}`
            + `&PageSize=${paginator.pageSize}`
            + (filter.guid != undefined ? `&Criteria.guid=${filter.guid}` : '')
            + (filter.folio != undefined && filter.folio != '' ? `&Criteria.folio=${filter.folio}` : '')
            + (filter.Name != undefined && filter.Name != '' ? `&Criteria.Name=${filter.Name}` : '')
           
        ).then( resp => {
            return resp;
        }).catch(error => {
            //getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async GetAdditionalFoundView(id: string): Promise<AxiosResponse<boolean>>{
        return  ApiService.get<boolean>(this.apiController + `/GetIncreaseView/${id}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async GetRetireView(id: string): Promise<AxiosResponse<boolean>>{
        return  ApiService.get<boolean>(this.apiController + `/GetRetireView/${id}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
}