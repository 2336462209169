export enum Actions {
    // action types
    GET_INFO_CASH = "GET_INFO_CASH"
    , OPEN_CASH = "OPEN_CASH"
    , ADDITIONAL_FUND = "ADDITIONAL_FUND"
    , CLOSE_CASH = 'CLOSE_CASH'
    , CASH_REGISTER_RETIRE = 'CASH_REGISTER_RETIRE'
    , GET_OPENED_CASH = 'GET_OPENED_CASH'
    , GET_CASHREGISTER_COMBO = 'GET_CASHREGISTER_COMBO'
    , GET_OPENING_PAGE = 'GET_OPENING_PAGE'
    , GET_SALE_CHARGES = 'GET_SALE_CHARGES'
    , CHANGE_PAGE = 'CHANGE_PAGE'
    , UPDATE_FILTERS = 'UPDATE_FILTERS'

    , CHANGE_PAGE_CHARGES = 'CHANGE_PAGE_CHARGES'
    , UPDATE_FILTERS_CHARGES = 'UPDATE_FILTERS_CHARGES'

    , APPROVAL_CLOSED = 'APPROVAL_CLOSED'

    , GET_SALES_CHARGES_TOTAL = "GET_SALES_CHARGES_TOTAL"
    , GET_VIEW_ADDITIONAL = "GET_VIEW_ADDITIONAL"
    , GET_VIEW_RETIRE = "GET_VIEW_RETIRE"

}

export default Actions;